import {gql} from '@apollo/client';

export const GET_MOVIE_QUERY = gql`
    query getChar{
        characters{
            results{
              id
              name
              image
              gender
              species
              origin{
                name
              }
            }
        }
    }
`