import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_MOVIE_QUERY } from "../graphql/Queries";
import Movie from "./Movie";

const Home = () => {

    const {loading, data, error} = useQuery(GET_MOVIE_QUERY);

    const [charactersList, updateCharactersList] = useState(undefined);

    const [filterList, updateGenderList] = useState([
        {
            id: 1,
            filterName: 'Gender',
            options: []
        },
        {
            id: 2,
            filterName: 'Species',
            options: []
        },
        {
            id: 3,
            filterName: 'Origin',
            options: []
        }
    ]);

    const [filteredCharList, updateFilteredCharList] = useState([]);

    const [activeFilters, updateActiveFilters] = useState([]);

    const [search, updateSearch] = useState('');

    const generateFilter = (fName, arr, arrParentInfo) => {
        if(arr.filterName === fName){
            if(!arr.options.includes(arrParentInfo)){
                arr.options.push(arrParentInfo);
            }
        }
    }

    const removeFilter = (filterToRemove, filterType) => {
        updateActiveFilters(activeFilters.filter((filterKey) => {
            if(filterKey.name === filterToRemove && filterKey.type === filterType){
                return null;
            } else{
                return filterKey;
            }
        }));
    }

    const toggleFilter = (elemToToggle, elemType) => {
        let newFilter = {
            type: elemType,
            name: elemToToggle
        }
        
        if(activeFilters.length > 0){
            if(activeFilters.filter(key => (key.name === elemToToggle && key.type === elemType)).length > 0){
                removeFilter(elemToToggle, elemType);
            } else{
                updateActiveFilters(() => [...activeFilters, newFilter]);
            }
        } else{
            updateActiveFilters(() => [...activeFilters, newFilter]);
        }
    }

    const filterByCategory = () => {
        const categoryGender = activeFilters.filter(key => (key.type === 'Gender') ? key : null);
        const categoryOrigin = activeFilters.filter(key => (key.type === 'Origin') ? key : null);
        const categorySpecies = activeFilters.filter(key => (key.type === 'Species') ? key : null);
        if(activeFilters.length > 0){
            updateFilteredCharList(
                charactersList.filter(filterKey => {
                    if(categoryGender.length > 0){
                        const arrFilterName = categoryGender.map(actKey => actKey.name);
                        // const arrFilterType = categoryFilteredArr.map(actKey => actKey.type);
                        if(arrFilterName.indexOf(filterKey.gender) !== -1){
                            return filterKey;
                        } else{
                            return null;
                        }  
                    } else{
                        return filterKey;
                    }        
                }).filter(filterKey => {
                    if(categoryOrigin.length > 0){
                        const arrFilterName = categoryOrigin.map(actKey => actKey.name);
                        // const arrFilterType = categoryFilteredArr.map(actKey => actKey.type);
                        if(arrFilterName.indexOf(filterKey.origin.name) !== -1){
                            return filterKey;
                        } else{
                            return null;
                        }  
                    } else{
                        return filterKey;
                    }        
                }).filter(filterKey => {
                    if(categorySpecies.length > 0){
                        const arrFilterName = categorySpecies.map(actKey => actKey.name);
                        // const arrFilterType = categoryFilteredArr.map(actKey => actKey.type);
                        if(arrFilterName.indexOf(filterKey.species) !== -1){
                            return filterKey;
                        } else{
                            return null;
                        }  
                    } else{
                        return filterKey;
                    }        
                })
            );
        } else{
            updateFilteredCharList(charactersList);
        }
    }

    const inputStatus = (type, name) => {
        if(activeFilters.filter(key => (key.name === name && key.type === type)).length > 0){
            return true;
            
        } else{
            return false;
        }
    }

    const searchFunc = (elem) => {
        if(elem.name.toLowerCase().indexOf(search.toLowerCase()) >= 0){
            return true;
        } else{
            return false;
        }
    }

    useEffect(() => {
        if(loading === false && data){
            const dataEl = data.characters.results;
            updateCharactersList(dataEl);
            updateFilteredCharList(dataEl);
            dataEl.map(itemKey => {
                updateGenderList(filterList.map(filterKey => {
                    generateFilter('Gender', filterKey, itemKey.gender);
                    generateFilter('Species', filterKey, itemKey.species);
                    generateFilter('Origin', filterKey, itemKey.origin.name);
                    return filterKey;
                }));
                return itemKey;
            });
        }
    }, [loading, data]);

    useEffect(() => {
        filterByCategory();
    }, [activeFilters]);

    if (loading){
        return 'Loading...'
    }
    if (error){
        return `Error! ${error.message}`
    }

    if (charactersList && filterList){
        return ( 
            <div className="Home">
                <div className="wrapper flex">
                    <aside className="filter">
                        <h2 className="filter__title">Filthering:</h2>
                        {
                            filterList.map(filterKey => (
                                <div className="filter__item" key={filterKey.id}>
                                    <div className="filter__subtitle">{filterKey.filterName}</div>
                                    {
                                        filterKey.options.map((optionKey, index) => (
                                            <div 
                                                className="filter__option" 
                                                key={index} 
                                                >
                                                <input type="checkbox" 
                                                    id={`option-${filterKey.id}-${index}`} 
                                                    onChange={() => toggleFilter(optionKey, filterKey.filterName)}
                                                    checked={inputStatus(filterKey.filterName, optionKey)} />
                                                <label 
                                                    htmlFor={`option-${filterKey.id}-${index}`} 
                                                    >{optionKey}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </aside>
                    <main className="content">
                        <div className="search flex">
                            <span>Search character:</span>
                            <input 
                                type="text" 
                                name="search" 
                                placeholder="Enter character name..." 
                                value={search}
                                onChange={(e) => updateSearch(e.target.value)} />
                        </div>
                        <div className="movies">
                            <div className="movies__head flex">
                                <h2 className="movies__title">Characters List</h2>
                                <div className="movies__filters flex">
                                    {
                                        activeFilters.map((filterKey, index) => (
                                            <div className="movies__filter" key={`filter-${index}`}>
                                                <span>{filterKey.name}</span>
                                                <span 
                                                    className="movies__filterclose" 
                                                    alt="" 
                                                    onClick={() => removeFilter(filterKey.name, filterKey.type)}>+</span>
                                            </div>
                                        ))
                                    }
                                    
                                </div>
                            </div>
                            <div className="movies__list flex">
                                {
                                    // filterByCategory().map(itemKey => (
                                    //     <Movie movie={itemKey} search={search} key={itemKey.id} />
                                    // ))
                                    filteredCharList.map(itemKey => (
                                        searchFunc(itemKey) ? <Movie movie={itemKey} search={search} key={itemKey.id} /> : null
                                    ))
                                }
                            </div>
                        </div>
                    </main>
                </div>
                
            </div>
        );
    }
}
 
export default Home;