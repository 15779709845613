const Movie = ({movie}) => {
    return ( 
        <div className="movies__item" >
            <img src={movie.image} alt="" className="movies__image" />
            <div className="movies__info">
                <span className="movies__name">{movie.name}</span>
                <div className="movies__text">
                    <b>Gender: </b>
                    <span>{movie.gender}</span>
                </div>
                <div className="movies__text">
                    <b>Origin: </b>
                    <span>{movie.origin.name}</span>
                </div>
                <div className="movies__text">
                    <b>Species: </b>
                    <span>{movie.species}</span>
                </div>
            </div>
        </div>
     );
}
 
export default Movie;